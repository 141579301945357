import createTypenameTypeguard from "./createTypenameTypeguard";

export const isBlocksAthleteQuoteStack = createTypenameTypeguard(
  "BlocksAthleteQuoteStack",
);

export const isBlocksCenteredProductHeroCan = createTypenameTypeguard(
  "BlocksCenteredProductHeroCan",
);

export const isBlocksCompanyStage =
  createTypenameTypeguard("BlocksCompanyStage");

export const isBlocksContactFormStage = createTypenameTypeguard(
  "BlocksContactFormStage",
);

export const isContactFormTicketField = createTypenameTypeguard(
  "ContactFormTicketField",
);

export const isContactFormUserField = createTypenameTypeguard(
  "ContactFormUserField",
);

export const isContactFormUserCustomField = createTypenameTypeguard(
  "ContactFormUserCustomField",
);

export const isContactFormTicketCustomField = createTypenameTypeguard(
  "ContactFormTicketCustomField",
);

export const isContactFormTicketAssignedField = createTypenameTypeguard(
  "ContactFormTicketAssignedField",
);

export const isBlocksContactForm = createTypenameTypeguard("BlocksContactForm");

export const isCtaCard = createTypenameTypeguard("CtaCard");

export const isDropdown = createTypenameTypeguard("Dropdown");

export const isAttachmentField = createTypenameTypeguard("AttachmentField");

export const isTextField = createTypenameTypeguard("TextField");

export const isBlocksHighlightProgressCarousel = createTypenameTypeguard(
  "BlocksHighlightProgressCarousel",
);

export const isBlocksHighlightsStage = createTypenameTypeguard(
  "BlocksHighlightsStage",
);

export const isBlocksIngredient = createTypenameTypeguard("BlocksIngredient");

export const isComparisonItem = createTypenameTypeguard("ComparisonItem");

export const isBlocksIngredientsStage = createTypenameTypeguard(
  "BlocksIngredientsStage",
);

export const isBlocksinteractiveHero = createTypenameTypeguard(
  "BlocksinteractiveHero",
);

export const isLifecyclePhase = createTypenameTypeguard("LifecyclePhase");

export const isContentTypeLocation = createTypenameTypeguard(
  "ContentTypeLocation",
);

export const isBlocksPcsMoodboard =
  createTypenameTypeguard("BlocksPcsMoodboard");

export const isBlocksNutritionInformation = createTypenameTypeguard(
  "BlocksNutritionInformation",
);

export const isBlocksOccasionStack = createTypenameTypeguard(
  "BlocksOccasionStack",
);

export const isBlocksProductDescription = createTypenameTypeguard(
  "BlocksProductDescription",
);

export const isBlocksProductHeroCan = createTypenameTypeguard(
  "BlocksProductHeroCan",
);

export const isBlocksProductHighlightPanel = createTypenameTypeguard(
  "BlocksProductHighlightPanel",
);

export const isBlocksProductRail = createTypenameTypeguard("BlocksProductRail");

export const isBlocksProductStage =
  createTypenameTypeguard("BlocksProductStage");

export const isBlocksProductTeaserCard = createTypenameTypeguard(
  "BlocksProductTeaserCard",
);

export const isBlocksQaArticle = createTypenameTypeguard("BlocksQaArticle");

export const isBlocksQaStage = createTypenameTypeguard("BlocksQaStage");

export const isBlocksRecipeSlider =
  createTypenameTypeguard("BlocksRecipeSlider");

export const isArtwork = createTypenameTypeguard("Artwork");

export const isSupportModule = createTypenameTypeguard("SupportModule");

export const isButton = createTypenameTypeguard("Button");

export const isDropdownRail = createTypenameTypeguard("DropdownRail");

export const isSearchField = createTypenameTypeguard("SearchField");

export const isContactAssistant = createTypenameTypeguard("ContactAssistant");

export const isBlocksSustainabilityQuiz = createTypenameTypeguard(
  "BlocksSustainabilityQuiz",
);

export const isBlocksVideo = createTypenameTypeguard("BlocksVideo");

export const isBlocksWorbCarousel =
  createTypenameTypeguard("BlocksWorbCarousel");

export const isBlocksExperienceWorb = createTypenameTypeguard(
  "BlocksExperienceWorb",
);

export const isBlocksWorbRail = createTypenameTypeguard("BlocksWorbRail");

export const isBlocksNewLifecycle =
  createTypenameTypeguard("BlocksNewLifecycle");

export const isAsset = createTypenameTypeguard("Asset");

export const isBlocksAnimatedProductTeaserCard = createTypenameTypeguard(
  "BlocksAnimatedProductTeaserCard",
);

export const isBlocksStayTuned = createTypenameTypeguard("BlocksStayTuned");

export const isImage = createTypenameTypeguard("Image");

export const isBlocksCartoonsOccasionStack = createTypenameTypeguard(
  "BlocksCartoonsOccasionStack",
);

export const isBlocksQuote = createTypenameTypeguard("BlocksQuote");
