"use client";

import { assertNotNullish } from "./assertNotNullish";
import { createContext, useContext } from "react";

export type TextDirection = "ltr" | "rtl";

const TextDirectionContext = createContext<TextDirection | undefined>(
  undefined,
);

export const TextDirectionContextProvider = (
  args: React.ComponentProps<typeof TextDirectionContext.Provider>,
) => <TextDirectionContext.Provider {...args} />;

export const useTextDirectionContext = (): TextDirection => {
  const context = useContext(TextDirectionContext);

  assertNotNullish(
    context,
    "useTextDirectionContext was used outside of Provider",
  );

  return context;
};
