"use client";

import { useTextDirectionContext } from "../../../../helpers/TextDirectionContext";
import { CardStack } from "../../../card-stack/card-stack";
import classNames from "classnames/bind";
import { useObserveElementRef } from "../../../../helpers/in-viewport/in-viewport";
import { getContentfulVideoUrl } from "../../../../helpers/getContentfulVideoUrl";
import { helpers } from "@contentful/rich-text-types";
import { notNull } from "../../../../helpers/notNull";
import { ImgHTMLAttributes, useEffect, useState } from "react";
import { useCardStackState } from "../../../../helpers/useCardStackState";
import { LifecycleCard } from "../../../card-stack/lifecycle-card/lifecycle-card";
import { responsiveImageHelper } from "../../../../helpers/responsiveImageHelper";
import { CosmosButton, CosmosIconChevronDown } from "@cosmos/web/react";
import { Kicker } from "../../../kicker/kicker";
import { useImageLoading } from "../../../../helpers/ImageLoadingContext";
import styles from "./lifecycle-phase.module.css";
import {
  LifecyclePhaseType,
  PhaseTextType,
  PhasePanelColumnsType,
  PhaseImageType,
  PhaseSideImageColumnsType,
} from "../lifecycle.types";
import { getImageSource } from "../../../../helpers/getImageSource";
import { getStoryblokImageUrl } from "../../../../helpers/images/getStoryblokImageUrl";
import { getContentfulImageUrl } from "../../../../helpers/images/getContentfulImageUrl";

const EVERY_SECOND_ITEM = 2;
const cx = classNames.bind(styles);

export const LifecyclePhase = ({
  phase,
  characterVideoUrl,
  index,
  onEnterViewport,
}: {
  phase: LifecyclePhaseType;
  characterVideoUrl: string | null;
  index: number;
  onEnterViewport: (phaseId: string) => void;
}) => {
  const textDirection = useTextDirectionContext();
  const [isActive, setIsActive] = useState<boolean>(false);

  const { ref, hasIntersected, isIntersecting } =
    useObserveElementRef<HTMLDivElement>({
      threshold: 0,
      rootMargin: "-50% 0px",
    });

  useEffect(() => {
    if (isIntersecting) {
      onEnterViewport(phase.id);
    }
  }, [isIntersecting, onEnterViewport, phase.id]);

  const { cardStackState, cardStackActions } = useCardStackState({
    rtl: textDirection === "rtl",
  });

  return (
    <div
      className={cx("container", {
        "container--silver-bg": index % EVERY_SECOND_ITEM !== 0,
      })}
      id={phase.title?.toLowerCase()}
      ref={ref}
    >
      <div className={cx("inner")}>
        <section className={cx("content")}>
          {phase.title && (
            <h2 className={cx("title")}>
              <Kicker kind="bare" text={phase.title} />
            </h2>
          )}
          {characterVideoUrl && hasIntersected && (
            <div
              className={cx(
                "video-wrapper",
                {
                  "video-wrapper--gradient-grey":
                    index % EVERY_SECOND_ITEM !== 0,
                },
                {
                  "video-wrapper--gradient-white":
                    index % EVERY_SECOND_ITEM === 0,
                },
              )}
            >
              <video
                className={cx("video")}
                preload="auto"
                autoPlay={true}
                playsInline={true}
                muted={true}
                loop={true}
                src={
                  characterVideoUrl
                    ? getContentfulVideoUrl(characterVideoUrl)
                    : undefined
                }
              />
            </div>
          )}
          <div
            className={cx("facts-wrapper", {
              "facts-wrapper--no-asset": !characterVideoUrl,
            })}
          >
            <p
              className={cx("quote")}
              data-align={index % EVERY_SECOND_ITEM ? "end" : "start"}
              data-width={phase.cardsCollection?.length ? "default" : "full"}
            >
              {phase.quote}
            </p>

            {phase.cardsCollection && phase.cardsCollection.length > 0 && (
              <div
                className={cx("cards-stack-wrapper", {
                  "container--align-left lifecycle-phase--flex-start":
                    index % EVERY_SECOND_ITEM,
                })}
                data-align={index % EVERY_SECOND_ITEM ? "start" : "end"}
              >
                <div className={cx("card-stack-inner-wrapper")}>
                  <CardStack
                    cards={phase.cardsCollection}
                    cardStackState={cardStackState}
                    cardStackActions={cardStackActions}
                    hasNavigationDots={true}
                    renderCard={({ card }) => (
                      <LifecycleCard
                        smallBodyText={card.smallBodyText}
                        largeText={card.largeText}
                        kicker={phase.cardsHeadline ?? null}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            <div
              className={cx("read-more-link-wrapper")}
              data-active={isActive ? "true" : "false"}
              data-align={index % EVERY_SECOND_ITEM ? "end" : "start"}
              data-width={phase.cardsCollection?.length ? "default" : "full"}
            >
              {/* `aria-controls` does not get passed down to Shadow DOM */}
              <CosmosButton
                className={cx("read-more-link")}
                aria-expanded={isActive}
                aria-controls={phase.id}
                kind="link"
                icon-placement="after"
                onClick={() => setIsActive((current) => !current)}
              >
                <CosmosIconChevronDown
                  slot="icon"
                  className={cx("read-more-link-icon")}
                />
                {isActive ? phase.readLessText : phase.readMoreText}
              </CosmosButton>
            </div>
            <div className={cx("hidden-content")} hidden={!isActive}>
              <div className={cx("hidden-content-inner")}>
                <div className={cx("hidden-content-inner-margin")}>
                  {phase.phaseBlocksCollection
                    ?.filter(notNull)
                    .map((item, index) => {
                      switch (item.__typename) {
                        case "PhaseText":
                          return <PhaseText key={index} item={item} />;
                        case "PhasePanelColumns":
                          return (
                            <PhasePanelColumns
                              key={index}
                              kicker={item.kicker}
                              id={item.id}
                              panelsCollection={item.panelsCollection}
                            />
                          );
                        case "PhaseImage":
                          return <PhaseImage key={index} item={item} />;
                        case "PhaseSideImageColumns":
                          return (
                            <PhaseSideImageColumns key={index} item={item} />
                          );
                        default:
                          return null;
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export function contentfulRichTextToSideImageColumns(
  json: import("@contentful/rich-text-types").Document,
) {
  return (
    <>
      {json.content.map((data, bodyTextIndex) => (
        <p key={bodyTextIndex} className={cx("phase-side-image-column-text")}>
          {data.content.map((paragraph, paragraphIndex) => {
            if (paragraph.nodeType === "hyperlink") {
              return (
                <a
                  key={paragraphIndex}
                  className={cx("phase-side-image-column-hyperlink")}
                  href={paragraph.data.uri}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {paragraph.content.filter(helpers.isText)[0].value}
                </a>
              );
            }

            if (helpers.isText(paragraph)) {
              return paragraph.value;
            }
          })}
        </p>
      ))}
    </>
  );
}

export function contentfulPhaseTextToReactNode(
  json: import("@contentful/rich-text-types").Document,
) {
  return (
    <>
      {json.content.map((data, index) => (
        <p key={index} className={cx("text-intro")}>
          {data.content.map((paragraph, paragraphIndex) => {
            if (paragraph.nodeType === "hyperlink") {
              return (
                <a
                  key={paragraphIndex}
                  className={cx("text-intro-hyperlink")}
                  href={paragraph.data.uri}
                >
                  {paragraph.content.filter(helpers.isText)[0].value}
                </a>
              );
            }

            if (helpers.isText(paragraph)) {
              return paragraph.marks &&
                paragraph.marks[0] &&
                (paragraph.marks[0].type === "bold" ||
                  paragraph.marks[0].type === "italic") ? (
                <span
                  key={paragraphIndex}
                  className={cx(`text-intro--${paragraph.marks[0].type}`)}
                >
                  {paragraph.value}
                </span>
              ) : (
                paragraph.value
              );
            }
          })}
        </p>
      ))}
    </>
  );
}

function PhaseText({ item }: { item: PhaseTextType }) {
  // 'Rich Text' field
  return <div className={cx("text-intro-wrapper")}>{item.text}</div>;
}

function PhasePanelColumns({
  kicker,
  panelsCollection,
}: Omit<PhasePanelColumnsType, "__typename">) {
  return (
    <div className={cx("panel-column-wrapper")}>
      {kicker && (
        <span className={cx("kicker-wrapper")}>
          <Kicker kind="underlined" text={kicker} />
        </span>
      )}
      <div className={cx("panel-column-content")}>
        {panelsCollection?.filter(notNull).map((panel, index) => {
          const imageUrl = panel.image?.url;
          const imageSource = imageUrl ? getImageSource(imageUrl) : null;

          if (!panel.image || !imageUrl) {
            return null;
          }

          return (
            <div key={index} className={cx("panel-column")}>
              {(() => {
                const commonImageAttributes = {
                  className: cx("panel-column-image"),
                  alt: panel.image?.description ?? undefined,
                  sizes: "300px",
                  src: imageUrl,
                };

                switch (imageSource) {
                  case "contentful":
                    return (
                      <img
                        srcSet={[300, 450, 600]
                          .map((width) => {
                            const src = getContentfulImageUrl(imageUrl, {
                              width,
                              fm: "webp",
                            });
                            return `${src} ${width}w`;
                          })
                          .join(", ")}
                        {...commonImageAttributes}
                      />
                    );
                  case "storyblok":
                    return (
                      <img
                        {...commonImageAttributes}
                        srcSet={[300, 450, 600]
                          .map((width) => {
                            const src = getStoryblokImageUrl(imageUrl, {
                              width,
                              format: "webp",
                            });
                            return `${src} ${width}w`;
                          })
                          .join(", ")}
                      />
                    );
                  default:
                    return <img {...commonImageAttributes} />;
                }
              })()}
              <div className={cx("panel-column-card")}>
                <h3 className={cx("panel-column-headline")}>
                  {panel.headline}
                </h3>
                <p className={cx("panel-column-text")}>{panel.panelText}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function PhaseImage({ item }: { item: PhaseImageType }) {
  const imageLoading = useImageLoading();
  const imageUrl = item.image?.url;
  const imageSource = imageUrl ? getImageSource(imageUrl) : null;

  const srcSet =
    imageUrl && imageSource
      ? {
          contentful: responsiveImageHelper.createContentfulSrcSet(imageUrl),
          storyblok: responsiveImageHelper.createStoryblokSrcSet(imageUrl),
        }[imageSource]
      : undefined;

  return (
    <div className={cx("phase-image-wrapper")}>
      {item.kicker && (
        <span className={cx("kicker-wrapper")}>
          <Kicker kind="underlined" text={item.kicker} />
        </span>
      )}
      {item.image?.url && (
        <img
          loading={imageLoading}
          srcSet={srcSet}
          sizes="min(680px, 100vw)"
          className={cx("phase-image")}
          alt={item.image.description ?? ""}
        />
      )}
    </div>
  );
}

function PhaseSideImageColumns({
  item,
}: {
  item: NonNullable<PhaseSideImageColumnsType>;
}) {
  return (
    <div className={cx("phase-side-image-column-wrapper")}>
      {item.sideImageColumnsCollection?.filter(notNull).map((panel, index) => {
        const imageUrl = panel.image?.url;
        const imageSource = imageUrl ? getImageSource(imageUrl) : null;

        return (
          <div key={index} className={cx("phase-side-image-column-item")}>
            {panel.image?.url &&
              (() => {
                const imageUrl = panel.image.url;

                const commonImageAttributes: ImgHTMLAttributes<HTMLImageElement> =
                  {
                    className: cx("phase-side-image-column-image"),
                    alt: panel.image?.description ?? undefined,
                    sizes:
                      "(width >= 1000px) 140px, (width >= 768px) 170px, 300px",
                    src: imageUrl,
                  };

                const widths = [140, 170, 300].flatMap((width) => [
                  width,
                  width * 1.5,
                  width * 2,
                ]);

                switch (imageSource) {
                  case "contentful":
                    return (
                      <img
                        srcSet={widths
                          .map((width) => {
                            const src = getContentfulImageUrl(imageUrl, {
                              width,
                              fm: "webp",
                            });
                            return `${src} ${width}w`;
                          })
                          .join(", ")}
                        {...commonImageAttributes}
                      />
                    );
                  case "storyblok":
                    return (
                      <img
                        {...commonImageAttributes}
                        srcSet={widths
                          .map((width) => {
                            const src = getStoryblokImageUrl(imageUrl, {
                              width,
                              format: "webp",
                            });
                            return `${src} ${width}w`;
                          })
                          .join(", ")}
                      />
                    );
                  default:
                    return <img {...commonImageAttributes} />;
                }
              })()}
            <div className={cx("phase-side-image-column-text-wrapper")}>
              <h3 className={cx("phase-side-image-column-headline")}>
                {panel.headline}
              </h3>
              {panel.bodyText}
            </div>
          </div>
        );
      })}
    </div>
  );
}
