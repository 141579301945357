import { Kicker } from "../../kicker/kicker";
import classnames from "classnames/bind";
import styles from "./lifecycle-card.module.css";

const cx = classnames.bind(styles);

export const LifecycleCard = ({
  largeText,
  smallBodyText,
  kicker,
}: {
  largeText: React.ReactNode;
  smallBodyText: React.ReactNode;
  kicker: string | null;
}) => {
  return (
    <div className={cx("container")}>
      <div className={cx("container_content")}>
        <span className={cx("kicker-wrapper")}>
          <Kicker kind="bare" text={kicker} />
        </span>
        <div className={cx("fact")}>
          {largeText && <p className={cx("text-large")}>{largeText}</p>}
          <div className={cx("text-small")}>{smallBodyText}</div>
        </div>
      </div>
    </div>
  );
};
